import HomeIcon from "@mui/icons-material/Home";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import StarIcon from "@mui/icons-material/Star";
import CodeIcon from "@mui/icons-material/Code";
import FoundationIcon from "@mui/icons-material/Foundation";
// import MusicNoteIcon from "@mui/icons-material/MusicNote";
// import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
// import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
// import LiveTvIcon from "@mui/icons-material/LiveTv";
// import SchoolIcon from "@mui/icons-material/School";
// import FaceRetouchingNaturalIcon from "@mui/icons-material/FaceRetouchingNatural";
// import CheckroomIcon from "@mui/icons-material/Checkroom";
// import GraphicEqIcon from "@mui/icons-material/GraphicEq";
// import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
// import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
// import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";

export const logo = "https://i.ibb.co/C27v4sB/tadatek.png";
// export const logo = "https://i.ibb.co/ZHMGS8y/tadatek-logo.png";

export const categories = [
  { name: "TADATek", icon: <HomeIcon /> },
  { name: "TADATek Insights", icon: <NewspaperIcon /> },
  { name: "Charles Hoskinson", icon: <StarIcon /> },
  { name: "InputOuput", icon: <FoundationIcon /> },
  { name: "IOGAcademy", icon: <CodeIcon /> },
  // { name: "JS Mastery", icon: <CodeIcon /> },
  // { name: "Coding", icon: <CodeIcon /> },
  // { name: "ReactJS", icon: <CodeIcon /> },
  // { name: "NextJS", icon: <CodeIcon /> },
  // { name: "Music", icon: <MusicNoteIcon /> },
  // { name: "Education", icon: <SchoolIcon /> },
  // { name: "Podcast", icon: <GraphicEqIcon /> },
  // { name: "Movie", icon: <OndemandVideoIcon /> },
  // { name: "Gaming", icon: <SportsEsportsIcon /> },
  // { name: "Live", icon: <LiveTvIcon /> },
  // { name: "Sport", icon: <FitnessCenterIcon /> },
  // { name: "Fashion", icon: <CheckroomIcon /> },
  // { name: "Beauty", icon: <FaceRetouchingNaturalIcon /> },
  // { name: "Comedy", icon: <TheaterComedyIcon /> },
  // { name: "Gym", icon: <FitnessCenterIcon /> },
  // { name: "Crypto", icon: <DeveloperModeIcon /> },
];

export const demoThumbnailUrl =
  "https://i.ibb.co/rtwxzk3/Cardano-Guide-by-TADATek-Insights.png";
export const demoChannelUrl = "/channel/UC9J88LtC7d-JpVilGwTcpVQ";
export const demoVideoUrl = "/video/ht7Pjleuph8";
export const demoChannelTitle = "TADATek | Cardano Ecosystem";
export const demoVideoTitle = "TADATek Introduction";
export const demoProfilePicture =
  "http://dergipark.org.tr/assets/app/images/buddy_sample.png";
